<template>
  <div>
    <el-dialog
      :title="mode === 'edit' ? '编辑' : '添加'"
      :model-value="modelValue"
      width="640px"
      @close="close"
      :close-on-click-modal="false"
    >
      <div>
        <el-form ref="form" :model="form" :rules="rules" label-width="120px">
          <el-form-item label="图片：" prop="image">
            <uploads v-model="form.image"></uploads>
          </el-form-item>
          <el-form-item label="优惠券名称：" prop="title">
            <el-input v-model="form.title"></el-input>
          </el-form-item>
          <el-form-item label="消费多少可用：" prop="least">
            <el-input v-model="form.least" type="number"></el-input>
          </el-form-item>
          <el-form-item label="优惠券金额：" prop="value">
            <el-input v-model="form.value" type="number"></el-input>
          </el-form-item>
          <el-form-item label="开始时间：" prop="starttime">
            <el-date-picker
              v-model="form.starttime"
              :clearable="false"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="结束时间：" prop="endtime">
            <el-date-picker
              v-model="form.endtime"
              :clearable="false"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="使用类型：" prop="type">
            <el-radio-group v-model="form.type">
              <el-radio
                v-for="(item, index) in COUPON_USE_TYPE_OPTIONS"
                :key="index"
                :label="item.value"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item label="发行数量：" prop="distribute">
            <el-input v-model="form.distribute" type="number"></el-input>
          </el-form-item>
          <el-form-item label="每人限领数：" prop="limit">
            <el-input v-model="form.limit" type="number"></el-input>
          </el-form-item>
          <el-form-item label="消耗积分：" prop="score">
            <el-input v-model="form.score" type="number"></el-input>
          </el-form-item>
          <el-form-item label="兑换码：" prop="exchangeCode">
            <el-input v-model="form.exchangeCode"></el-input>
          </el-form-item>
          <el-form-item label="店铺：" prop="shopId">
            <el-select v-model="form.shopId" placeholder="请选择" multiple>
              <el-option
                v-for="(item, index) in shopOptions"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="使用说明：" prop="instructions">
            <el-input v-model="form.instructions"></el-input>
          </el-form-item>
          <el-form-item label="是否上架：" prop="switchFlag">
            <el-radio-group v-model="form.switchFlag">
              <el-radio :label=1>是</el-radio>
              <el-radio :label=0>否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="派发用户：" prop="userId">
            <el-select
              v-model="form.userId"
              placeholder="请选择"
              filterable
              clearable
            >
              <el-option
                v-for="(item, index) in customerOptions"
                :key="index"
                :label="item.mobile"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div>
          <el-button @click="resetHandle">重 置</el-button>
          <el-button type="primary" @click="submitHandle">确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { COUPON_USE_TYPE_OPTIONS } from "@/helpers/constants";
import { addCoupon, editCoupon } from "@/apis/unidrink/coupon";
import { getShopOptions } from "@/apis/unidrink/shop";
import { getCustomerList } from "@/apis/user/customer";
import { msgErr, msgSuc } from "@/helpers/message";
import { cloneDeep } from "lodash";
import { getShopList } from "@/apis/auth";
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    editData: {
      type: Object,
      default: () => {},
    },
    mode: {
      type: String,
      default: "add", // add-新增；edit-编辑
    },
  },
  data() {
    return {
      COUPON_USE_TYPE_OPTIONS: COUPON_USE_TYPE_OPTIONS,
      initForm: {
        starttime: new Date(),
        endtime: new Date(),
        distribute: 1,
        limit: 1,
        score: 0,
        type: 0,
        switchFlag: 1,
        shopId: [0],
        instructions: "",
      },
      form: {},
      rules: {
        image: [{ required: true, message: "图片不能为空", trigger: "blur" }],
        title: [
          { required: true, message: "优惠券名称不能为空", trigger: "blur" },
        ],
        least: [
          { required: true, message: "消费多少可用不能为空", trigger: "blur" },
        ],
        value: [
          { required: true, message: "优惠券金额不能为空", trigger: "blur" },
        ],
        starttime: [
          { required: true, message: "开始时间不能为空", trigger: "blur" },
        ],
        endtime: [
          { required: true, message: "结束时间不能为空", trigger: "blur" },
        ],
        distribute: [
          { required: true, message: "发行数量不能为空", trigger: "blur" },
        ],
        limit: [
          { required: true, message: "每人限领数不能为空", trigger: "blur" },
        ],
        score: [
          { required: true, message: "消耗积分不能为空", trigger: "blur" },
        ],
        shopId: [
          { required: true, message: "消耗积分不能为空", trigger: "blur" },
        ],
      },
      shopOptions: [],
      customerOptions: [],
    };
  },
  emits: ["update:modelValue", "refresh"],
  created() {
    this.form = cloneDeep(this.initForm);
  },
  watch: {
    modelValue(val) {
      if (val) {
        getShopOptions(this.shopOptions);
        this.getCustomerOptions();
        this.resetForm();
        this.editReflect();
      }
    },
  },
  methods: {
    close() {
      this.$emit("update:modelValue", false);
    },

    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.clearValidate();
        this.form = cloneDeep(this.initForm);
      }
    },

    resetHandle() {
      this.resetForm();
      this.editReflect();
    },

    editReflect() {
      if (this.mode === "edit") {
        this.form = cloneDeep(this.editData);
        this.form.shopId = this.form.shopId
          .split(",")
          .map((item) => Number(item));
        this.form.starttime = new Date(Number(this.form.starttime) * 1000);
        this.form.endtime = new Date(Number(this.form.endtime) * 1000);
        this.form.userId = this.form.userId === 0 ? "" : this.form.userId;
      }
    },

    submitHandle() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }

        console.log(this.form);

        const promise = this.mode === "edit" ? editCoupon : addCoupon;
        promise(this.form).then(({ code, message }) => {
          if (code === "0000") {
            this.close();
            this.$emit("refresh");
            msgSuc("操作成功！");
          } else {
            msgErr(message);
          }
        });
      });
    },

    getCustomerOptions() {
      getCustomerList({ sort: "user.id", order: "desc" }).then(
        ({ rows, total }) => {
          this.customerOptions = rows.filter((item) => !!item.mobile);
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
